import React from 'react';
import NetworkGraph from './NetworkGraph';

function App() {
  // Get token from URL parameter
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  if (!token) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-500">No access token provided</div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <div className="h-2 bg-gradient-to-r from-[#1c6941] to-[#72ab32]" />
      <div className="flex-1 overflow-hidden">
        <NetworkGraph token={token} />
      </div>
    </div>
  );
}

export default App;